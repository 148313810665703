
<script setup lang="ts">
	import { useLocationsStore } from '~/stores/LocationsStore';
	import { useMenuStore } from '~/stores/MenuStore';
	import { useSettingsStore } from '~/stores/SettingsStore';

	const locationsStore = useLocationsStore();
	await locationsStore.getList();
	await locationsStore.getPickUpList();

	const menuStore = useMenuStore();
	await menuStore.getTop();
	await menuStore.getBottom();

	const settingsStore = useSettingsStore();
	await settingsStore.getPhone();
	await settingsStore.getLinks();
</script>

<template>
	<div class="wrapper">
		<AppHeader :opacity="true" :menuStore="menuStore.top" />
		<slot />
		<AppFooter :menuStore="menuStore.bottom" />
	</div>
</template>