<script lang="ts" setup>
	import { defineProps } from 'vue'
	import { useSettingsStore } from '~/stores/SettingsStore';

	const settingsStore = useSettingsStore();

	const prop = defineProps({
	  menuStore: { default: {}, type: [Object] },
	})

</script>

<template>
	<div class="footer">
		<div class="container">
			<div class="footer__content">
				<div class="footer__top">
					<div class="footer__social">
						<a href="https://vk.com/mycarrental" target="_blank" class="footer__social-icon">
							<img src="/img/vk.svg" alt="">
						</a>
						<div class="footer__social-text">Подписывайтесь на нас в соцсетях, <br>узнавайте об акциях и получайте скидки!</div>
					</div>
					<div class="footer__phone">
						<a href="tel:+78007774848" class="footer__phone-link">{{ settingsStore.phone }}</a>
						<div class="footer__phone-text">Мы работаем с 8:00 до 20:00 (МСК)</div>
					</div>
				</div>
				<div class="footer__main">
					<div class="footer__menu">
						<div class="footer__column" v-for="(menuItem, index) in menuStore" :key="index">
							<nuxt-link :to="`${item.url}`" class="footer__link" v-for="item in menuItem" :key="item.id">{{item.name}}</nuxt-link>
						</div>
					</div>
					<div class="footer__payments">
						<div class="footer__payments-column">
							<div class="footer__payments-item">
								<img src="/img/payment-1.png" alt="">
							</div>
							<div class="footer__payments-item">
								<img src="/img/payment-2.png" alt="">
							</div>
							<div class="footer__payments-item">
								<img src="/img/payment-3.png" alt="">
							</div>
							<div class="footer__payments-item">
								<img src="/img/payment-4.png" alt="">
							</div>
						</div>
						<div class="footer__payments-column">
							<div class="footer__payments-item">
								<img src="/img/payment-5.png" alt="">
							</div>
							<div class="footer__payments-item">
								<img src="/img/payment-6.png" alt="">
							</div>
							<div class="footer__payments-item">
								<img src="/img/payment-7.png" alt="">
							</div>
							<div class="footer__payments-item">
								<img src="/img/payment-8.png" alt="">
							</div>
						</div>
					</div>
				</div>
				<div class="footer__bottom">
					<div class="footer__logo"></div>
					<div class="footer__copy">
						2015-2024 © ООО МОЙ АВТОПРОКАТ
					</div>
				</div>
			</div>
		</div>
	</div>
</template>