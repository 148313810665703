import { defineStore } from 'pinia';

export const useMenuStore = defineStore('menuStore', {
  state: () => ({
    top: null,
    bottom: null,
  }),
  actions: {
    async getTop() {
      const { data: top } = await useFetch('/api/v2/menu/top');
      if (top) this.top = top.value;
    },
    async getBottom() {
      const { data: bottom } = await useFetch('/api/v2/menu/bottom');
      if (bottom) this.bottom = bottom.value;
    },
  }
});